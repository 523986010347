import * as React from "react";
import { Dropdown, InterfaceDropdown } from "./Dropdown";
import { InterfaceLabel, Label } from "../Label";
import { FlexRow } from "../Container";

export const LabelAndDropdown: React.FunctionComponent<
  InterfaceLabel & InterfaceDropdown & { label: string }
> = ({ label, icon, dimensions, ...rest }) => {
  return (
    <FlexRow
      flex={{ fjc: "justify-between", fai: "items-center" }}
      dimensions={{ w: "w-100" }}
    >
      <Label size={4} icon={icon}>
        {label}
      </Label>
      <Dropdown
        placeholder={"None"}
        dimensions={Object.assign({ w: ["w-100", "mw3"] }, dimensions)}
        {...rest}
      />
    </FlexRow>
  );
};
