import * as React from "react";
import { Page } from "../src/components/Page";
import { LoadingPage } from "../src/components/LoadingPage";
import { useState } from "react";
import { useAudio } from "react-use";
import { FlexColumn, FlexRow } from "../src/components/Container";
import { LabelAndDropdown } from "../src/components/fields/LabelAndDropdown";
import Slider from "react-input-slider";
import { colours } from "../src/themes/colours";
import { Label } from "../src/components/Label";
import LogoSVG from "../src/assets/svg/logo.svg";
import { H3 } from "../src/components/Typography";

const SpeakerOptions = [
  {
    label: "Male 1",
    value: "a13"
  },
  {
    label: "Male 2",
    value: "a09"
  },
  {
    label: "Male 3",
    value: "a25"
  },
  {
    label: "Female 1",
    value: "a07"
  },
  {
    label: "Female 2",
    value: "a08"
  },
  {
    label: "Female 3",
    value: "a22"
  }
];

const AxisOptions = [
  {
    label: "Neutral",
    value: 0
  },
  {
    label: "Happy",
    value: 1
  },
  {
    label: "Sad",
    value: 2
  },
  {
    label: "Angry",
    value: 3
  },
  {
    label: "Dubious",
    value: 4
  },
  {
    label: "Conversational",
    value: 5
  },
  {
    label: "Product Review",
    value: 7
  },
  {
    label: "Story Telling",
    value: 8
  }
];

const SentenceOptions = [
  {
    label:
      "El amor y el interés se fueron al campo un día, pero más pudo el interés que el amor que le tenía.",
    value: "0"
  },
  {
    label:
      "La cartuja de Parma es la novela francesa más grande de todos los tiempos.",
    value: "1"
  },
  {
    label: "No dejes de mandarme cartas, y que sean largas.",
    value: "2"
  }
];

export default () => {
  if (typeof window === "undefined") {
    return <LoadingPage />;
  }

  const [speaker, setSpeaker] = useState<string>(SpeakerOptions[0].value);
  const [intensity, setIntensity] = useState<number>(0);
  const [axis, setAxis] = useState<number>(1);
  const [sentence, setSentence] = useState<string>(SentenceOptions[0].value);

  const src = `https://papercup-storage.s3-eu-west-1.amazonaws.com/demos/EmotionForcing-cVAE-sliders/neural_vocoded/${speaker}_sentence${sentence}_emotion${axis}_intensity${intensity}.wav`;

  const [audio, state, controls, ref] = useAudio({
    src,
    controls: true
  });

  return (
    <Page accessLevel={"public"}>
      <FlexColumn style={{ width: "450px" }}>
        <FlexRow
          flex={{ fjc: "justify-center", fai: "items-center" }}
          dimensions={{ pad: "pb4", m: "mb4" }}
          border={{ b: "bb", bw: "bw1", bc: "b--gray-20" }}
        >
          <LogoSVG width={"34px"} className="mh5" />
          <H3>Papercup Demo</H3>
        </FlexRow>
        <LabelAndDropdown
          label={"Speaker"}
          options={SpeakerOptions}
          value={SpeakerOptions.find(s => s.value == speaker)}
          onChange={v => v && "value" in v && setSpeaker(v.value)}
        />
        <LabelAndDropdown
          label={"Axis"}
          options={AxisOptions}
          value={AxisOptions.find(a => a.value == axis)}
          onChange={v => v && "value" in v && setAxis(v.value)}
        />
        <LabelAndDropdown
          label={"Sentence"}
          options={SentenceOptions}
          value={SentenceOptions.find(s => s.value == sentence)}
          onChange={v => v && "value" in v && setSentence(v.value)}
        />
        <FlexRow
          flex={{ fjc: "justify-between", fai: "items-center" }}
          dimensions={{ w: "w-100" }}
        >
          <Label size={4}>Intensity</Label>
          <Label size={3}>{intensity}</Label>
          <Slider
            axis="x"
            x={intensity}
            xmax={4}
            xstep={1}
            onChange={({ x }: { x: number }) => setIntensity(x)}
            styles={{
              track: {
                backgroundColor: colours["c-secondary"],
                height: 4,
                width: 300,
                margin: 16
              },
              active: {
                backgroundColor: colours["c-brand"]
              },
              thumb: {
                width: 12,
                height: 12,
                top: -2,
                boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.26)",
                backgroundColor: colours["c-primary"],
                cursor: "pointer"
              }
            }}
          />
        </FlexRow>
        <FlexColumn
          flex={{ fjc: "justify-center", fai: "items-center" }}
          dimensions={{ pad: "pt4", m: "mt4" }}
          border={{ b: "bt", bw: "bw1", bc: "b--gray-20" }}
        >
          {audio}
        </FlexColumn>
      </FlexColumn>
    </Page>
  );
};
